'use client';

import { FormoAnalyticsProvider } from '@formo/analytics';
import React, { FC } from 'react';

type FormoAnalyticsProviderProps = {
  writeKey: string;
  children: React.ReactNode;
};

export const AnalyticsProvider: FC<FormoAnalyticsProviderProps> = ({
  writeKey,
  children,
}) => {
  return (
    <FormoAnalyticsProvider
      writeKey={writeKey}
      options={{
        trackLocalhost: false,
        logger: {
          enabled: true,
          levels: ['error', 'warn', 'info'],
        },
      }}
    >
      {children}
    </FormoAnalyticsProvider>
  );
};

export default AnalyticsProvider;
