'use client';

import { REGEX } from '@formo/shared';
import {
  useParams,
  usePathname,
  useSelectedLayoutSegments,
} from 'next/navigation';
import { useEffect } from 'react';

const setPageTitle = (title: string) => {
  if (window.document) {
    window.document.title = `${title} - Formo`;
  }
};

const PageTitle = () => {
  const path = usePathname();
  const layoutSegments = useSelectedLayoutSegments();
  const { action, projectId } = useParams() as {
    action?: string;
    projectId?: string;
  };

  useEffect(() => {
    if (layoutSegments.indexOf('(project-specific)') !== -1) {
      const lastPart = layoutSegments.pop();
      switch (lastPart) {
        case 'settings':
          setPageTitle('Project Settings');
          return;
        default:
          return;
      }
    }
    if (layoutSegments.indexOf('(analytics)') !== -1) {
      const lastPart = layoutSegments.pop() || '';
      if (REGEX.ETHEREUM_ADDRESS.test(lastPart)) {
        setPageTitle('Address ' + lastPart);
        return;
      }
      switch (lastPart) {
        case 'setup':
          setPageTitle('Install');
          return;
        case 'overview':
          setPageTitle('Overview');
          return;
        case 'activity':
          setPageTitle('Activity');
          return;
        case 'users':
          setPageTitle('Users');
          return;
        default:
          return;
      }
    }
    if (layoutSegments.indexOf('(teamTier)') !== -1) {
      const lastPart = layoutSegments.pop();
      switch (lastPart) {
        case 'billing':
          setPageTitle('Team Billing');
          return;
        case 'upgrade':
          setPageTitle('Team Upgrade');
          return;
        default:
          return;
      }
    }
    if (layoutSegments.indexOf('(dashboard)') !== -1) {
      const lastPart = layoutSegments.pop();
      const semiLastPart = layoutSegments.pop();
      if (semiLastPart === 'settings' && lastPart === 'member') {
        setPageTitle('Member Settings');
        return;
      }
      switch (lastPart) {
        case 'settings':
          setPageTitle('Workspace Settings');
          return;
        case 'intro':
          setPageTitle('Analytics Intro');
          return;
        default:
          return;
      }
    }
    if (layoutSegments.indexOf('(build)') !== -1) {
      if (path === '/' || path.startsWith('/teams')) {
        setPageTitle('Dashboard');
        return;
      }
      if (action === 'edit') {
        setPageTitle('Edit Form');
        return;
      }
      if (action === 'share') {
        setPageTitle('Share Form');
        return;
      }
      if (action === 'responses') {
        setPageTitle('Form Responses');
        return;
      }
      if (action === 'settings') {
        setPageTitle('Form Settings');
        return;
      }
    }
    if (layoutSegments.indexOf('onboarding') !== -1) {
      const lastPart = layoutSegments.pop();
      switch (lastPart) {
        case 'choose-your-plan':
          setPageTitle('Choose your plan');
          return;
        case 'set-up-workspace':
          setPageTitle('Set up your workspace');
          return;
        case 'tell-us-about-yourself':
          setPageTitle('Tell us about yourself');
          return;
        default:
          return;
      }
    }

    // Add more cases as needed
  }, [action, path, projectId]);

  return null;
};

export default PageTitle;
